<template>
    <page :styleWrap='{background: "#f6f6f6"}' ref='wrap' :footer='65'>
        <div ref='content'>
            <div class="f_flex f_w">
                <div
                    class="labelItem f_flex f_a_c f_j_c"
                    v-for='(item, index) in get_labelList'
                    :key='index'>
                    <i class="iconfont icon-close iconClose" @click='deleteItem(item)'></i>
                    {{item? item.labelName:'' }}
                </div>
            </div>
            <div class="searchPanel f_flex f_a_c f_j_sb">
                <search class="f_g1" v-model="word" placeholder="搜索" shape='round'></search>
                <div class="cancle act_link" @click='cancleFn'>取消</div>
            </div>
            <div class="resContent">
                <div style='padding-left: 15px; padding-bottom: 15px' @click='createLabelFn' v-if='isNew && word'
                     class="f_flex f_a_c underline">
                    <div>{{ word }}</div>
                    <div class="newLabel theme_c">新建</div>
                </div>

            <div class="labelArchor">常用</div>
                <checkbox-group v-model="labelList" @change="changePickFn" style="width: 100%">
                    <checkbox :name='item.labelId' class="resItem underline " v-for='(item, index) in recentLabelList'
                              :key='index'>
                        <div style='padding-left: 15px' class="f_flex f_a_c">
                            <div>{{ item?item.labelName:'' }}</div>
                        </div>
                    </checkbox>
                </checkbox-group>
                
                   <div class="labelArchor">全部</div>
                <checkbox-group v-model="labelList" @change="changePickFn" style="width: 100%">
                    <checkbox :name='item.labelId' class="resItem underline " v-for='(item, index) in result'
                              :key='index'>
                        <div style='padding-left: 15px' class="f_flex f_a_c">
                            <div>{{ item?item.labelName:'' }}</div>
                        </div>
                    </checkbox>
                </checkbox-group>
            </div>
            <div style="height: 80px"></div>
        </div>
        <div slot='footer' class="footer f_flex f_j_e f_a_c">
            <button style="width: 110px;" @click='confirmFn' class="btn btn_radius theme_bg">确定</button>
        </div>
    </page>
</template>

<script>
import {Checkbox, CheckboxGroup, Search} from 'vant';
import {mapActions, mapGetters} from 'vuex'
import styleNoApi from '@/api/styleNo'
import scrollMixins from '@/common/mixins/scroll'

let labelArray = []
export default {
    components: {
        Search,
        Checkbox,
        CheckboxGroup
    },
    mixins: [scrollMixins],
    data: () => ({
        labelList: [],
        chooseList: [],
        pageNo: 1,
        pageSize: 20,
        word: '',
        result: [],
        recentLabelList:[],
        allResult: [],
        isEnd: false,
        isNew: false,
        allPickList: []
    }),
    watch: {
        word (n, o) {
            if (n !== o) {
                this.$global.debounce (() => {
                    this.pageNo = 1
                    this.$set (this, 'result', [])
                    this.isEnd = false
                    // console.log ('debounce, postlebel');
                    this.getList ()
                })
            }
        }
    },
    computed: {
        ...mapGetters (['get_labelList']),
    },
    methods: {
        ...mapActions ([
            'set_labelList'
        ]),
        initData (isCopy) {
            this.word = ''
            if (!isCopy) {
                this.$set (this, 'labelList', [])
            }
            
            this.$set (this, 'chooseList', [])
            this.$set (this, 'allResult', [])
            this.$set (this, 'result', [])
            this.$set (this, 'allPickList', [])
            this.pageNo = 1
            this.isEnd = false
            this.set_labelList ([])
            
        },
        cancleFn () {
            this.$router.back ()
            // this.initData()
        },
        deleteItem (item) {
            const {labelId} = item
            // const index = this.labelList.indexOf(labelId)
            // this.labelList.splice(index, 1)
            // this.set_labelList(this.allPickList)
            // console.log(this.allPickList)
            const list = this.get_labelList.filter (item => {
                // console.log(labelId,'id')
                // console.log(item.labelId,'key')
                return item.labelId !== labelId
            })
            const arr = []
            list.forEach (item => {
                arr.push (item.labelId)
            })
            this.$set (this, 'labelList', arr)
            this.$set (this, 'allPickList', list)
            this.set_labelList (this.allPickList)
        },
        changePickFn (e) {
            // console.log(e)
            const list = this.allResult
            list.push(...this.recentLabelList)
            const pick = e
            const _list = list.filter(item => {
                return pick.includes(item.labelId)
            })
            const map = {}
            _list.forEach(item => {
                map[item.labelId] =  item
            });
            
            const allList = [...this.get_labelList,...this.allResult].
                filter(item=> e.includes(item.labelId)).map(item=>{
                    const {labelName,labelId} = item
                return {labelName,labelId}
            }).
                map(item=>JSON.stringify(item))
            // console.log(allList)
            this.allPickList = Array.from (new Set (allList)).map (item => JSON.parse (item))
            // console.log(_l)
            this.set_labelList(this.allPickList)
        },
        getList (flag = false) {
            const {companyId, postsSource, groupId} = this.$route.query
            const companyInfo = this.$global.getItem ('companyInfo', true)
            if (!this.isEnd) {
                styleNoApi.labelList ({
                    groupId: groupId,
                    labelName: this.word,
                    pageSize: this.pageSize,
                    pageNo: this.pageNo,
                    labelSource: postsSource,
                    bizId: companyId || companyInfo?.companyId,
                }).then (res => {
                    if (res.data && res.data.labelList) {
                        const list = res.data.labelList
                        const status = res.data.reqLabelNameExistStatus
                        if (status === 2 && this.pageNo == 1) {
                            this.isNew = true
                        } else {
                            this.isNew = false
                        }
                        this.result.push (...list)

                        if (this.recentLabelList.length == 0) {
                            this.recentLabelList.push(...res.data.recentLabelList)
                        }
                        
                        this.allResult.push (...list)
                        if (this.$route.query.from === 'copy' && flag) {
                            this.set_labelList (labelArray)
                        }
                    }
                    if (!res.data.endFlag) {
                        this.pageNo++
                    } else {
                        this.isEnd = true
                    }
                })
            }
        },
        createLabelFn () {
            const {companyId, postsSource} = this.$route.query
            if (this.word) {
                const obj = {
                    labelSource: Number (postsSource),
                    bizId: companyId,
                    labelName: this.word
                }
                styleNoApi.labelCreate (obj).then (res => {
                    if (res.data) {
                        this.$toast (res.data.msg)
                        const item = {
                            labelName: this.word.trim (),
                            labelId: res.data.bizId,
                            labelGroupName: ''
                        }
                        this.allResult.push (item)
                        this.labelList.push (res.data.bizId)
                        this.$set (this.allPickList, this.allPickList.length, item)
                        this.set_labelList (this.allPickList)
                        this.isNew = false
                    }
                })
            } else {
                this.$toast ('请输入标签')
            }
        },
        confirmFn () {
            if (this.allPickList.length) {
                this.set_labelList (this.allPickList)
                // this.word = ''
                this.$router.back ()
            } else {
                this.$toast ('请输入标签')
            }
        }
    },
    destroyed () {
        labelArray = []
    },
    beforeRouteEnter (to, from, next) {
        next ((vm) => {
            const fromEvent = to.query.from
            if (to.name === 'postLabel') {
                // console.log ('=====:', vm.get_labelList);
                if (!(vm.get_labelList && vm.get_labelList.length) || fromEvent === 'copy') {
                    if (fromEvent === 'copy') {
                        
                        vm.labelList = vm.get_labelList.map (item => item.labelId)
                        // console.log(vm.get_labelList)
                        labelArray = JSON.parse (JSON.stringify (vm.get_labelList)).filter(item=>!!item)
                        vm.allPickList = JSON.parse (JSON.stringify (vm.get_labelList)).filter(item=>!!item)
                        vm.set_labelList([])
                        vm.initData (true)
                    } else {
                        vm.initData (false)
                    }
                    setTimeout (() => {
                        console.log ('beforeEnter, postlebel');
                        
                        vm.getList (true)
                        const wrap = vm.$refs.wrap.$refs.pageContent_contain
                        const content = vm.$refs.content
                        vm.onContentScroll (wrap, content, vm.isEnd, () => {
                            vm.getList ()
                        })
                    }, 20)
                }
            }
        })
    },
}
</script>

<style lang="less" scoped>
.searchPanel {
    padding-left: 10px;
    background: #fff;
    z-index: 9;
}

.labelArchor {
    z-index: 1;
    box-sizing: border-box;
    padding: 0 16px;
    color: #323233;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    background-color: transparent;

}

.labelItem {
    position: relative;
    padding: 10px 20px;
    min-width: 108px;
    background: #F1F1F1;
    box-sizing: border-box;
    margin: 11px 6px;
    
    .iconClose {
        color: #bebebe;
        font-size: 14px;
        position: absolute;
        left: 4px;
        top: 4px;
    }
}

.cancle {
    font-size: 15px;
    color: #4A4A4A;
    margin: 0 15px;
}

.resContent {
    padding: 0px;
}

.resItem {
    font-size: 16px;
    color: #2D2D2D;
    padding: 12px 20px;
    background: #fff;
}

.newLabel {
    font-size: 12px;
    border: 1px solid #4F92E6;
    padding: 1px 2px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 7px;
}

.footer {
    height: 65px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding-right: 12px;
}
</style>
